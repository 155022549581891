.errorLink {
    color: red;
    text-decoration: none;
    font-size: 1.5rem;
}


.errorLink:hover {
    color: red;
    text-decoration: none;
}