@import "./../../styles/helpers";

.container {
    display: flex;
    justify-content: center;
    align-items: center; }

.content {
    background-color: white;
    box-shadow: 0px 0px 22px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.input {
    border: 1px solid black;
    padding: 8px;
    width: 100%;
    height: 60%;
    cursor: pointer;
    &:disabled {
        background-color: #f4f4f4;
        cursor: auto; } }




.DatePicker {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
