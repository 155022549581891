@import "./../../styles/helpers";

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #494529;
    width: 100%;
    height: 100px;
    color: white;
    max-width: 880px;
    padding-left: 20px;
    padding-right: 20px; }

.grid {
    width: 100%;
    max-width: 880px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }


.input {
    padding: 4px;
    background-color: transparent;
    color: white;
    box-shadow: 0px 0px 22px 8px rgba(0, 0, 0, 0.4), 10px 32px 48px -8px rgba(0, 0, 0, 0.2); }

.title {
    font-size: 20px; }

.content {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%; }

.GridToolbar {
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }

.cell {
    display: flex;
    justify-content: end;
    height: 100%;
    width: 100%; }

.selectArea {
    display: flex;
    align-items: center;
    gap: 8px; }

.select {
    color: black; }

