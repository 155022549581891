@import "./../../styles/helpers";

.content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }


.title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }

.butonynaprihlasenie {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between; }

.button_sign_in_help {
    width: 48%;
    border-radius: 12px; }

.loader {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center; }

.body {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.button_area {
    display: flex;
    justify-content: center;
    align-items: center; }

.input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px; }

.badLogin {
    color: red;
    font-weight: 700;
    margin-bottom: 10px; }
