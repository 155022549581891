/* 
    Pomocné farby
     --mainGreen: "#006600";
    --mainWhite: "#FFFFFF";
    --secondGreen: "#017d22";
    --mainBrown: "#494529";
    --secondBrown: "#DDD9C4";
    --mainGray: "#333";
*/

* {
    font-family: 'Signika', sans-serif;
 
}

@media print {
    @page {
        size: landscape
    }
}
